* {
  background-color: #43858e;
}

html {
  position: relative;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  padding: 10px 40px 40px 40px;
  font-family: 'Montaga', serif;
  position: relative;
  height: 100%;
}

a {
  color: black;
  text-decoration: underline;
}

a:visited {
  color: black;
  text-decoration: underline;
}

a:hover {
  color: white;
  background-color: #89005b;
  font-style: italic;
}
