.loading {
  text-align: center;
  margin-top: 40px;
}

.home {
  display: flex;
  padding-bottom: 25px;
}

.hamburger {
  display: none;
}

.content {
  width: 90%;
}

.mobile-bar {
  display: none;
}

.topbar {
  height: 150px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  width: 85%;
  margin: 0;
  font-family: 'Yatra One', cursive !important;
  font-size: 60px;
  color: white !important;
  text-align: center;
  cursor: pointer;
  text-decoration: none !important;
}

.title:hover {
  text-decoration: none !important;
  color: white !important;
  background-color: #43858e !important;
  font-style: normal !important;
}

.index {
  margin-top: 40px;
}

.index-tag {
  cursor: pointer;
  text-decoration: none !important;
}

.index-tag:hover {
  text-decoration: underline !important;
  color: black !important;
  background-color: #43858e !important;
  font-style: normal !important;
}

.page-button-wrap {
  padding-top: 25px;
  display: flex;
  justify-content: center;
}

.page-button {
  padding-left: 10px;
  cursor: pointer;
  text-decoration: underline;
}

.posts {
  display: flex;
  flex-wrap: wrap;
  margin-right: 10px;
}

.post {
  width: 20%;
  padding-left: 30px;
  padding-bottom: 30px;
}

.post-title-clickable {
  font-size: 32px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none !important;
}

.post-title-clickable:hover {
  text-decoration: none !important;
  color: black !important;
  background-color: #43858e !important;
  font-style: normal !important;
}

.post-image {
  width: 100%;
}

.post-tags {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
}

.post-tags-tag {
  margin-left: 5px;
  display: flex;
  text-decoration: none !important;
}

.post-tags-tag:hover {
  text-decoration: underline !important;
  color: black !important;
  background-color: #43858e !important;
  font-style: normal !important;
}

.post1, .post2, .post3, .post4, .post5, .post6, .post7  {
  width: 20%;
  padding-top: 10px;
  padding-left: 60px;
  -webkit-animation-duration: 20s; /* Safari 4.0 - 8.0 */
  animation-duration: 20s;
  -webkit-animation-fill-mode: forwards;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
}

.small {
  width: 20%;
}
.medium {
  width: 40%;
}
.large {
  width: 70%;
}

.post1 {
  animation-name: post1;
  -webkit-animation-name: post1; /* Safari 4.0 - 8.0 */
}

.post2 {
  -webkit-animation-name: post2; /* Safari 4.0 - 8.0 */
  animation-name: post2;
}

.post3 {
  -webkit-animation-name: post3; /* Safari 4.0 - 8.0 */
  animation-name: post3;
}

.post4 {
  -webkit-animation-name: post4; /* Safari 4.0 - 8.0 */
  animation-name: post4;
}
.post5 {
  -webkit-animation-name: post5; /* Safari 4.0 - 8.0 */
  animation-name: post5;
}
.post6 {
  -webkit-animation-name: post6; /* Safari 4.0 - 8.0 */
  animation-name: post6;
}
.post7 {
  -webkit-animation-name: post7; /* Safari 4.0 - 8.0 */
  animation-name: post7;
}

/* MOBILE STYLES */

@media (max-width: 700px) {

  body {
    padding: 0px;
  }

  .mobile-bar {
    display: initial;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin: 10px 0px 0px 13px;
  }

  .topbar {
    height: auto;
  }

  .title {
    width: 100%;
    line-height: 55px;
  }

  .moon-load {
    display: none;
  }

  .moon {
    display: none;
  }

  .mobile-moon-box {
    width: 30px;
  }

  .moon-mobile-menu {
    display: initial;
  }

  .page-button-wrap {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .index {
    display: none;
  }

  .edit-content {
    width: 90%;
    padding: 20px;
  }

  .hamburger {
    display: inline;
    font-size: 30px;
  }

  .post1, .post2, .post3, .post4, .post5, .post6, .post7, .small, .medium, .large  {
    width: 90%;
    padding-bottom: 45px;
    padding-left: 30px;
    animation: none;
  }

  .mobile-menu {
    position: absolute;
    width: 100%;
    background-color: white;
    z-index: 10;
  }

  .mobile-menu-header {
    display: flex;
    background-color: white;
    justify-content: space-between;
  }

  .mobile-menu-close {
    background-color: white;
    font-size: 25px;
  }

  .mobile-index-title {
    background-color: white;
    font-size: 25px;
  }

  .mobile-index {
    height: 100vh;
    padding: 20px;
    background-color: white;
    overflow: scroll;
  }

  .mobile-index-tag {
    padding-top: 10px;
    font-size: 20px;
    background-color: white;
    display: block;
    text-decoration: none !important;
  }

}

@keyframes post1 {
  from {margin-left: 200px; margin-top: 110px}
  to {margin-left: 250px; margin-top: 130}
}

@-webkit-keyframes post1 {
  from {margin-left: 200px; margin-top: 110px}
  to {margin-left: 250px; margin-top: 130}
}

@keyframes post2 {
  from {margin-left: 20px; margin-top: 0px}
  to {margin-left: 40px; margin-top: 20}
}

@-webkit-keyframes post2 {
  from {margin-left: 20px; margin-top: 0px}
  to {margin-left: 40px; margin-top: 20}
}

@keyframes post3 {
  from {margin-left:0px; margin-top: 80px}
  to {margin-left: 50px; margin-top: 130px}
}

@-webkit-keyframes post3 {
  from {margin-left:0px; margin-top: 80px}
  to {margin-left: 50px; margin-top: 130px}
}

@keyframes post4 {
  from {margin-left:30px; margin-top: 300px}
  to {margin-left: 5px; margin-top: 320px}
}

@-webkit-keyframes post4 {
  from {margin-left:30px; margin-top: 300px}
  to {margin-left: 5px; margin-top: 320px}
}

@keyframes post5 {
  from {margin-left:400px; margin-top: 100px}
  to {margin-left: 380px; margin-top: 80px}
}
@-webkit-keyframes post5 {
  from {margin-left:400px; margin-top: 100px}
  to {margin-left: 380px; margin-top: 80px}
}

@keyframes post6 {
  from {margin-left:20px; margin-top: 0px}
  to {margin-left: 220px; margin-top: 20px}
}

@-webkit-keyframes post6 {
  from {margin-left:20px; margin-top: 0px}
  to {margin-left: 220px; margin-top: 20px}
}

@keyframes post7 {
  from {margin-left:80px; margin-top: 20px}
  to {margin-left: 60px; margin-top: 50px}
}

@-webkit-keyframes post7 {
  from {margin-left:80px; margin-top: 20px}
  to {margin-left: 60px; margin-top: 50px}
}
